<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="#0C2D5E"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 16.8751C6.20304 16.8751 3.125 13.7971 3.125 10.0001C3.125 6.20316 6.20304 3.12512 10 3.12512C13.797 3.12512 16.875 6.20316 16.875 10.0001C16.875 13.7971 13.797 16.8751 10 16.8751ZM1.875 10.0001C1.875 14.4874 5.51269 18.1251 10 18.1251C14.4873 18.1251 18.125 14.4874 18.125 10.0001C18.125 5.51281 14.4873 1.87512 10 1.87512C5.51269 1.87512 1.875 5.51281 1.875 10.0001Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 13.9585C10.3452 13.9585 10.625 13.6786 10.625 13.3335V10.0001C10.625 9.65494 10.3452 9.37512 10 9.37512C9.65482 9.37512 9.375 9.65494 9.375 10.0001V13.3335C9.375 13.6786 9.65482 13.9585 10 13.9585Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.375 6.66679C9.375 7.01197 9.65482 7.29179 10 7.29179H10.0083C10.3535 7.29179 10.6333 7.01197 10.6333 6.66679C10.6333 6.32161 10.3535 6.04179 10.0083 6.04179H10C9.65482 6.04179 9.375 6.32161 9.375 6.66679Z"
    />
  </svg>
</template>
